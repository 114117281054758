@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: elza, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
   /* background-color: #1a1d29; */
   background-size:  cover;
   background-position: center ;
   background-repeat: no-repeat; 
   height: 100vh;
   /* background: linear-gradient(to right, #ff7e5f, #feb47b); */
   /* background-image: url('https://t3.ftcdn.net/jpg/03/53/28/08/240_F_353280884_oyeOLzk0EyvfC4pvixbvcfA4s745SaX6.jpg'); */
   background-size: cover;
   overflow-x: auto;
   color: #fff;
   /* background-blend-mode: overlay;  */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
header {
  /* background-color: rgb(72, 72, 118); */
  color: black;
  padding: 2em;
  text-align:end;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;

}

.logo {
  display: flex;
  align-items:flex-start;
  justify-content: flex-start;
  margin-bottom: 1em;
}

.logo img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.logo h1 {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
}

nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
}

nav li {
  margin-right: 20px;
}

nav a {
  color: #fff;
  text-decoration: none;
}

nav a:hover {
  color: #ccc;
}